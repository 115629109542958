var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.cargando)?_c('loading'):_vm._e(),_c('div',{staticClass:"modal fade",staticStyle:{"background-color":"#00000082"},attrs:{"id":"modal-form-tifNovedades","tabindex":"-1","role":"dialog","aria-hidden":"true","data-backdrop":"static","data-keyboard":"false"}},[_c('div',{staticClass:"modal-dialog modal-xl",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header bg-frontera-top-left pt-2 pb-2"},[_c('h4',{staticClass:"modal-title text-white"},[_c('strong',[_vm._v(" Novedades Turno:")]),_vm._v(" "+_vm._s(_vm.turno_id)+" "),_c('button',{staticClass:"btn btn-sm bg-purple",attrs:{"type":"button","data-toggle":"modal","data-target":"#modal-form-detTurno"},on:{"click":function($event){return _vm.llenarModalDetTurno(_vm.turno_id)}}},[_vm._v(" Ver Detalle Turno ")])]),_c('button',{ref:"closeModalNovedad",staticClass:"close text-white",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close","id":"close-modal"},on:{"click":function($event){return _vm.cerrarModal()}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"form-group col-sm-2"},[(
                _vm.flag == false &&
                  _vm.$store.getters.can('tif.conciliaciones.novedades.frontera')
              )?_c('button',{staticClass:"btn btn-sm bg-navy",attrs:{"type":"button"},on:{"click":function($event){return _vm.agregar()}}},[_vm._v(" Agregar Novedad ")]):_vm._e()]),(_vm.flag == true)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"fecha_inicio"}},[_vm._v("Fecha y Hora Inicial")]),_c('div',{staticClass:"row ml-1"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.fecha_inicio),expression:"form.fecha_inicio"}],staticClass:"form-control form-control-sm col-md-7",class:_vm.$v.form.fecha_inicio.$invalid ? 'is-invalid' : 'is-valid',staticStyle:{"font-size":"13px"},attrs:{"type":"date"},domProps:{"value":(_vm.form.fecha_inicio)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "fecha_inicio", $event.target.value)},function($event){return _vm.validaFechas()}]}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.hora_ini),expression:"form.hora_ini"},{name:"mask",rawName:"v-mask",value:({
                    mask: '99:99:99',
                    hourFormat: '23',
                  }),expression:"{\n                    mask: '99:99:99',\n                    hourFormat: '23',\n                  }"}],staticClass:"form-control form-control-sm col-md-5",class:_vm.$v.form.hora_ini.$invalid ? 'is-invalid' : 'is-valid',staticStyle:{"font-size":"13px"},attrs:{"type":"text"},domProps:{"value":(_vm.form.hora_ini)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "hora_ini", $event.target.value)},function($event){return _vm.validaFechas()}]}})])]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"fecha_fin"}},[_vm._v("Fecha y Hora Final")]),_c('div',{staticClass:"row ml-1"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.fecha_fin),expression:"form.fecha_fin"}],staticClass:"form-control form-control-sm col-md-7",class:_vm.$v.form.fecha_fin.$invalid ? 'is-invalid' : 'is-valid',staticStyle:{"font-size":"13px"},attrs:{"type":"date"},domProps:{"value":(_vm.form.fecha_fin)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "fecha_fin", $event.target.value)},function($event){return _vm.validaFechas()}]}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.hora_fin),expression:"form.hora_fin"},{name:"mask",rawName:"v-mask",value:({
                    mask: '99:99:99',
                    hourFormat: '23',
                  }),expression:"{\n                    mask: '99:99:99',\n                    hourFormat: '23',\n                  }"}],staticClass:"form-control form-control-sm col-md-5",class:_vm.$v.form.hora_fin.$invalid ? 'is-invalid' : 'is-valid',staticStyle:{"font-size":"13px"},attrs:{"type":"text"},domProps:{"value":(_vm.form.hora_fin)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "hora_fin", $event.target.value)},function($event){return _vm.validaFechas()}]}})])]),_c('div',{staticClass:"form-group col-md-2"},[_c('label',[_vm._v("Tiempo")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.tiempo),expression:"form.tiempo"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.tiempo.$invalid ? 'is-invalid' : 'is-valid',attrs:{"type":"number","disabled":"","placeholder":"Tiempo"},domProps:{"value":(_vm.form.tiempo)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "tiempo", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-3 center"},[_c('label',[_vm._v("Sitio")]),_c('v-select',{staticClass:"form-control form-control-sm p-0",class:[
                  _vm.$v.sitio.$invalid ? 'is-invalid' : 'is-valid',
                  _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                ],attrs:{"placeholder":"Nombre","label":"nombre","options":_vm.listasForm.sitio},on:{"input":function($event){return _vm.seleccionarSitio()}},model:{value:(_vm.sitio),callback:function ($$v) {_vm.sitio=$$v},expression:"sitio"}})],1),_c('div',{staticClass:"form-group col-md-4"},[_c('label',[_vm._v("Tipo")]),_c('v-select',{staticClass:"form-control form-control-sm p-0",class:[
                  _vm.$v.tipo.$invalid ? 'is-invalid' : 'is-valid',
                  _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                ],attrs:{"placeholder":"Descripcion","label":"descripcion","options":_vm.listasForm.tipo},on:{"input":function($event){return _vm.seleccionarTipo()}},model:{value:(_vm.tipo),callback:function ($$v) {_vm.tipo=$$v},expression:"tipo"}})],1),_c('div',{staticClass:"form-group col-md-2"},[_c('label',[_vm._v("Longitud")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.longitud),expression:"form.longitud"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.longitud.$invalid ? 'is-invalid' : 'is-valid',attrs:{"type":"number","disabled":"","placeholder":"Longitud"},domProps:{"value":(_vm.form.longitud)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "longitud", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-2"},[_c('label',[_vm._v("Latitud")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.latitud),expression:"form.latitud"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.latitud.$invalid ? 'is-invalid' : 'is-valid',attrs:{"type":"number","disabled":"","placeholder":"Latitud"},domProps:{"value":(_vm.form.latitud)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "latitud", $event.target.value)}}})])]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-sm-2"},[(_vm.flag == true)?_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$v.$invalid),expression:"!$v.$invalid"}],staticClass:"btn btn-sm bg-navy",attrs:{"type":"button"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Guardar ")]):_vm._e(),(
                  _vm.flag == true &&
                    _vm.$store.getters.can(
                      'tif.conciliaciones.novedades.frontera'
                    )
                )?_c('button',{staticClass:"btn btn-sm bg-primary",attrs:{"type":"button","title":"Cancelar Cambio"},on:{"click":function($event){return _vm.cancelarCambio()}}},[_vm._v(" Volver ")]):_vm._e()])]),_c('div',{staticClass:"tab-content",attrs:{"id":"myTabContent"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('table',{staticClass:"table table-bordered table-striped table-hover table-sm"},[_c('thead',{staticClass:"thead"},[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v("Sitio")]),_c('th',[_vm._v("Tipo")]),_c('th',[_vm._v("Fecha inicio")]),_c('th',[_vm._v("Fecha final")]),_c('th',[_vm._v("Ubicación")]),_c('th',[_vm._v("Justificación Transportadora")]),_c('th',[_vm._v("Observación Frontera")]),_c('th',[_vm._v("Mapa")]),_c('th',[_vm._v("Indisponibilidad")]),(this.tipo_tarifa == 3)?_c('th',[_vm._v("Disponibilidad")]):_vm._e(),_c('th',[_vm._v("Acción")])])]),_c('tbody',_vm._l((_vm.turno),function(item){return _c('tr',{key:item.id,class:item.tipo_novedad == 4 || item.tipo_novedad == 10
                          ? 'table-warning'
                          : 'table-danger'},[_c('td',[_vm._v(_vm._s(item.id))]),(item.sitio)?_c('td',[_vm._v(_vm._s(item.sitio.nombre))]):_c('td'),_c('td',[_vm._v(_vm._s(item.tipo))]),_c('td',[_vm._v(_vm._s(item.fecha_ini))]),_c('td',[_vm._v(_vm._s(item.fecha_fin))]),_c('td',[_vm._v(_vm._s(item.lat_ini)+" "+_vm._s(item.lon_ini))]),_c('td',[(item.justificacion)?_c('button',{staticClass:"btn btn-sm bg-navy",attrs:{"type":"button"},on:{"click":function($event){return _vm.justi(item.justificacion)}}},[_c('i',{staticClass:"fas fa-user-secret"})]):_vm._e()]),(
                          _vm.$store.getters.can(
                            'tif.conciliaciones.novedades.frontera'
                          )
                        )?_c('td',[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(item.observacion_front),expression:"item.observacion_front"}],staticClass:"form-control",attrs:{"aria-label":"With textarea","disabled":item.tif_turno.tif_conciliacion ||
                              item.tipo_novedad == 4},domProps:{"value":(item.observacion_front)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(item, "observacion_front", $event.target.value)}}})]):_c('td',[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(item.observacion_front),expression:"item.observacion_front"}],staticClass:"form-control",attrs:{"aria-label":"With textarea","disabled":""},domProps:{"value":(item.observacion_front)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(item, "observacion_front", $event.target.value)}}})]),_c('td',[_c('button',{staticClass:"btn btn-sm bg-navy",attrs:{"type":"button"},on:{"click":function($event){return _vm.llenarMapa(item)}}},[_c('i',{staticClass:"fas fa-map-marked-alt"})])]),(
                          _vm.$store.getters.can(
                            'tif.conciliaciones.novedades.frontera'
                          )
                        )?_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.tiempo_indisponible),expression:"item.tiempo_indisponible"}],staticClass:"form-control form-control-sm",attrs:{"type":"number","disabled":item.tif_turno.tif_conciliacion ||
                              item.tipo_novedad == 4 ||
                              item.tipo_novedad == 10},domProps:{"value":(item.tiempo_indisponible)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(item, "tiempo_indisponible", $event.target.value)}}})]):_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.tiempo_indisponible),expression:"item.tiempo_indisponible"}],staticClass:"form-control form-control-sm",attrs:{"type":"number","disabled":""},domProps:{"value":(item.tiempo_indisponible)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(item, "tiempo_indisponible", $event.target.value)}}})]),(
                          _vm.$store.getters.can(
                            'tif.conciliaciones.novedades.frontera'
                          ) && _vm.tipo_tarifa == 3
                        )?_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.tiempo_disponible),expression:"item.tiempo_disponible"}],staticClass:"form-control form-control-sm",attrs:{"type":"number","disabled":item.tif_turno.tif_conciliacion},domProps:{"value":(item.tiempo_disponible)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(item, "tiempo_disponible", $event.target.value)}}})]):_vm._e(),(_vm.tipo_tarifa == 3)?_c('td',[(
                            _vm.$store.getters.can(
                              'tif.conciliaciones.novedades.frontera'
                            ) && !item.tif_turno.tif_conciliacion
                          )?_c('button',{staticClass:"btn btn-sm",class:item.created_at != item.updated_at
                              ? 'bg-success'
                              : 'bg-primary',attrs:{"type":"button"},on:{"click":function($event){return _vm.valDuracion(item)}}},[_c('i',{staticClass:"fas fa-check"})]):_vm._e()]):_c('td',[(
                            _vm.$store.getters.can(
                              'tif.conciliaciones.novedades.frontera'
                            ) && !item.tif_turno.tif_conciliacion
                          )?_c('button',{staticClass:"btn btn-sm",class:item.created_at != item.updated_at
                              ? 'bg-success'
                              : 'bg-primary',attrs:{"type":"button"},on:{"click":function($event){return _vm.editar(item)}}},[_c('i',{staticClass:"fas fa-check"})]):_vm._e()])])}),0)])])]),(_vm.justificacion == true)?_c('div',{staticClass:"card card-info card-outline collapsed-card col-4 mx-auto"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"input-group mb-3"},[_vm._m(1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tipo_j),expression:"tipo_j"}],staticClass:"form-control",attrs:{"type":"text","disabled":""},domProps:{"value":(_vm.tipo_j)},on:{"input":function($event){if($event.target.composing)return;_vm.tipo_j=$event.target.value}}})]),_c('div',{staticClass:"input-group"},[_vm._m(2),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.obv),expression:"obv"}],staticClass:"form-control",attrs:{"disabled":"","aria-label":"With textarea"},domProps:{"value":(_vm.obv)},on:{"input":function($event){if($event.target.composing)return;_vm.obv=$event.target.value}}})]),_c('label',{attrs:{"for":"link_fotografia"}},[_vm._v("Foto")]),(_vm.miniLogo)?_c('figure',{staticClass:"form-group"},[_c('div',{staticClass:"button-container"},[_c('img',{staticClass:"rounded mx-auto d-block",attrs:{"src":_vm.miniLogo,"alt":"Logo","width":"236","height":"125"}})])]):_vm._e()])]):_vm._e()])]),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"row"},[_c('gmap-map',{staticStyle:{"width":"100%","height":"450px"},attrs:{"center":_vm.center,"zoom":_vm.zoom,"options":{ streetViewControl: false }}},[_vm._l((_vm.markers),function(m,index){return _c('gmap-marker',{key:index,attrs:{"position":m.position,"icon":m.icon,"clickable":true,"draggable":true},on:{"dragend":_vm.addMarkers,"click":function($event){(_vm.center = m.position),
                      _vm.toggleInfoWindow(m.position, m.id, m.info)}}})}),_c('gmap-info-window',{attrs:{"options":_vm.infoOptions,"position":_vm.infoWindowPos,"opened":_vm.infoWinOpen},on:{"closeclick":function($event){_vm.infoWinOpen = false}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.infoContent)}})]),_vm._l((_vm.poligonos),function(poligono){return _c('gmap-polygon',{key:poligono.id,attrs:{"options":{ fillColor: poligono.color },"paths":poligono.path,"editable":false,"draggable":false},on:{"click":function($event){return _vm.toggleInfoWindowPoli(poligono, poligono.id, poligono.info)}}})}),_vm._l((_vm.circunferencias),function(circular){return _c('gmap-circle',{key:circular.id,attrs:{"options":{ fillColor: circular.color },"center":circular.center,"radius":circular.radius,"info":circular.info},on:{"click":function($event){return _vm.toggleInfoWindowCir(circular, circular.id, circular.info)}}})})],2)],1)])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header pt-2 pb-2"},[_c('h3',{staticClass:"card-title"},[_c('i',{staticClass:"fas fa-user-secret text-secondary"}),_vm._v(" Justificacion ")]),_c('div',{staticClass:"card-tools"},[_c('button',{staticClass:"btn btn-tool",attrs:{"type":"button","data-card-widget":"collapse","data-toggle":"tooltip","title":"Collapse"}},[_c('i',{staticClass:"fas fa-plus"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_vm._v("Tipo")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_vm._v("Observacion")])])
}]

export { render, staticRenderFns }