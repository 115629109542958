<template>
  <div>
    <loading v-if="cargando" />
    <div
      class="modal fade"
      id="modal-form-tifNovedades"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      style="background-color: #00000082"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">
              <strong> Novedades Turno:</strong> {{ turno_id }}
              <button
                type="button"
                class="btn btn-sm bg-purple"
                data-toggle="modal"
                data-target="#modal-form-detTurno"
                @click="llenarModalDetTurno(turno_id)"
              >
                Ver Detalle Turno
              </button>
            </h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModalNovedad"
              @click="cerrarModal()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group col-sm-2">
              <button
                type="button"
                class="btn btn-sm bg-navy"
                v-if="
                  flag == false &&
                    $store.getters.can('tif.conciliaciones.novedades.frontera')
                "
                @click="agregar()"
              >
                Agregar Novedad
              </button>
            </div>
            <div class="row" v-if="flag == true">
              <div class="form-group col-md-3">
                <label for="fecha_inicio">Fecha y Hora Inicial</label>
                <div class="row ml-1">
                  <input
                    type="date"
                    v-model="form.fecha_inicio"
                    style="font-size: 13px"
                    class="form-control form-control-sm col-md-7"
                    :class="
                      $v.form.fecha_inicio.$invalid ? 'is-invalid' : 'is-valid'
                    "
                    @input="validaFechas()"
                  />
                  <input
                    v-model="form.hora_ini"
                    type="text"
                    class="form-control form-control-sm col-md-5"
                    style="font-size: 13px"
                    v-mask="{
                      mask: '99:99:99',
                      hourFormat: '23',
                    }"
                    :class="
                      $v.form.hora_ini.$invalid ? 'is-invalid' : 'is-valid'
                    "
                    @input="validaFechas()"
                  />
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="fecha_fin">Fecha y Hora Final</label>
                <div class="row ml-1">
                  <input
                    type="date"
                    v-model="form.fecha_fin"
                    style="font-size: 13px"
                    class="form-control form-control-sm col-md-7"
                    :class="
                      $v.form.fecha_fin.$invalid ? 'is-invalid' : 'is-valid'
                    "
                    @input="validaFechas()"
                  />
                  <input
                    v-model="form.hora_fin"
                    type="text"
                    class="form-control form-control-sm col-md-5"
                    style="font-size: 13px"
                    v-mask="{
                      mask: '99:99:99',
                      hourFormat: '23',
                    }"
                    :class="
                      $v.form.hora_fin.$invalid ? 'is-invalid' : 'is-valid'
                    "
                    @input="validaFechas()"
                  />
                </div>
              </div>
              <div class="form-group col-md-2">
                <label>Tiempo</label>
                <input
                  type="number"
                  v-model="form.tiempo"
                  disabled
                  class="form-control form-control-sm"
                  placeholder="Tiempo"
                  :class="$v.form.tiempo.$invalid ? 'is-invalid' : 'is-valid'"
                />
              </div>
              <div class="form-group col-md-3 center">
                <label>Sitio</label>
                <v-select
                  :class="[
                    $v.sitio.$invalid ? 'is-invalid' : 'is-valid',
                    $store.getters.getDarkMode ? 'dark-vselect' : '',
                  ]"
                  v-model="sitio"
                  placeholder="Nombre"
                  label="nombre"
                  :options="listasForm.sitio"
                  @input="seleccionarSitio()"
                  class="form-control form-control-sm p-0"
                ></v-select>
              </div>
              <div class="form-group col-md-4">
                <label>Tipo</label>
                <v-select
                  :class="[
                    $v.tipo.$invalid ? 'is-invalid' : 'is-valid',
                    $store.getters.getDarkMode ? 'dark-vselect' : '',
                  ]"
                  v-model="tipo"
                  placeholder="Descripcion"
                  label="descripcion"
                  :options="listasForm.tipo"
                  @input="seleccionarTipo()"
                  class="form-control form-control-sm p-0"
                ></v-select>
              </div>
              <div class="form-group col-md-2">
                <label>Longitud</label>
                <input
                  type="number"
                  v-model="form.longitud"
                  disabled
                  class="form-control form-control-sm"
                  placeholder="Longitud"
                  :class="$v.form.longitud.$invalid ? 'is-invalid' : 'is-valid'"
                />
              </div>
              <div class="form-group col-md-2">
                <label>Latitud</label>
                <input
                  type="number"
                  v-model="form.latitud"
                  disabled
                  class="form-control form-control-sm"
                  placeholder="Latitud"
                  :class="$v.form.latitud.$invalid ? 'is-invalid' : 'is-valid'"
                />
              </div>
            </div>
            <div class="row">
              <div class="form-group col-sm-2">
                <button
                  type="button"
                  class="btn btn-sm bg-navy"
                  v-if="flag == true"
                  v-show="!$v.$invalid"
                  @click="save()"
                >
                  Guardar
                </button>
                <button
                  type="button"
                  class="btn btn-sm bg-primary"
                  title="Cancelar Cambio"
                  v-if="
                    flag == true &&
                      $store.getters.can(
                        'tif.conciliaciones.novedades.frontera'
                      )
                  "
                  @click="cancelarCambio()"
                >
                  Volver
                </button>
              </div>
            </div>

            <div class="tab-content" id="myTabContent">
              <div class="row">
                <div class="col-md-12">
                  <table
                    class="
                      table table-bordered table-striped table-hover table-sm
                    "
                  >
                    <thead class="thead">
                      <tr>
                        <th>#</th>
                        <th>Sitio</th>
                        <th>Tipo</th>
                        <th>Fecha inicio</th>
                        <th>Fecha final</th>
                        <th>Ubicación</th>
                        <th>Justificación Transportadora</th>
                        <th>Observación Frontera</th>
                        <th>Mapa</th>
                        <th>Indisponibilidad</th>
                        <th v-if="this.tipo_tarifa == 3">Disponibilidad</th>
                        <th>Acción</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in turno"
                        :key="item.id"
                        :class="
                          item.tipo_novedad == 4 || item.tipo_novedad == 10
                            ? 'table-warning'
                            : 'table-danger'
                        "
                      >
                        <td>{{ item.id }}</td>
                        <td v-if="item.sitio">{{ item.sitio.nombre }}</td>
                        <td v-else></td>
                        <td>{{ item.tipo }}</td>
                        <td>{{ item.fecha_ini }}</td>
                        <td>{{ item.fecha_fin }}</td>
                        <td>{{ item.lat_ini }} {{ item.lon_ini }}</td>
                        <td>
                          <button
                            type="button"
                            v-if="item.justificacion"
                            class="btn btn-sm bg-navy"
                            @click="justi(item.justificacion)"
                          >
                            <i class="fas fa-user-secret"></i>
                          </button>
                        </td>
                        <td
                          v-if="
                            $store.getters.can(
                              'tif.conciliaciones.novedades.frontera'
                            )
                          "
                        >
                          <textarea
                            class="form-control"
                            aria-label="With textarea"
                            v-model="item.observacion_front"
                            :disabled="
                              item.tif_turno.tif_conciliacion ||
                                item.tipo_novedad == 4
                            "
                          ></textarea>
                        </td>
                        <td v-else>
                          <textarea
                            class="form-control"
                            aria-label="With textarea"
                            disabled
                            v-model="item.observacion_front"
                          ></textarea>
                        </td>
                        <td>
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            @click="llenarMapa(item)"
                          >
                            <i class="fas fa-map-marked-alt"></i>
                          </button>
                        </td>
                        <td
                          v-if="
                            $store.getters.can(
                              'tif.conciliaciones.novedades.frontera'
                            )
                          "
                        >
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            v-model="item.tiempo_indisponible"
                            :disabled="
                              item.tif_turno.tif_conciliacion ||
                                item.tipo_novedad == 4 ||
                                item.tipo_novedad == 10
                            "
                          />
                        </td>
                        <td v-else>
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            disabled
                            v-model="item.tiempo_indisponible"
                          />
                        </td>
                        <td
                          v-if="
                            $store.getters.can(
                              'tif.conciliaciones.novedades.frontera'
                            ) && tipo_tarifa == 3
                          "
                        >
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            v-model="item.tiempo_disponible"
                            :disabled="item.tif_turno.tif_conciliacion"
                          />
                        </td>
                        <td v-if="tipo_tarifa == 3">
                          <button
                            type="button"
                            class="btn btn-sm"
                            :class="
                              item.created_at != item.updated_at
                                ? 'bg-success'
                                : 'bg-primary'
                            "
                            v-if="
                              $store.getters.can(
                                'tif.conciliaciones.novedades.frontera'
                              ) && !item.tif_turno.tif_conciliacion
                            "
                            @click="valDuracion(item)"
                          >
                            <i class="fas fa-check"></i>
                          </button>
                        </td>
                        <td v-else>
                          <button
                            type="button"
                            class="btn btn-sm"
                            :class="
                              item.created_at != item.updated_at
                                ? 'bg-success'
                                : 'bg-primary'
                            "
                            v-if="
                              $store.getters.can(
                                'tif.conciliaciones.novedades.frontera'
                              ) && !item.tif_turno.tif_conciliacion
                            "
                            @click="editar(item)"
                          >
                            <i class="fas fa-check"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                class="card card-info card-outline collapsed-card col-4 mx-auto"
                v-if="justificacion == true"
              >
                <div class="card-header pt-2 pb-2">
                  <h3 class="card-title">
                    <i class="fas fa-user-secret text-secondary"></i>
                    Justificacion
                  </h3>
                  <div class="card-tools">
                    <button
                      type="button"
                      data-card-widget="collapse"
                      data-toggle="tooltip"
                      title="Collapse"
                      class="btn btn-tool"
                    >
                      <i class="fas fa-plus"></i>
                    </button>
                  </div>
                </div>
                <div class="card-body">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Tipo</span>
                    </div>
                    <input
                      type="text"
                      disabled
                      class="form-control"
                      v-model="tipo_j"
                    />
                  </div>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Observacion</span>
                    </div>
                    <textarea
                      disabled
                      class="form-control"
                      aria-label="With textarea"
                      v-model="obv"
                    ></textarea>
                  </div>
                  <label for="link_fotografia">Foto</label>
                  <figure class="form-group" v-if="miniLogo">
                    <div class="button-container">
                      <img
                        :src="miniLogo"
                        alt="Logo"
                        width="236"
                        height="125"
                        class="rounded mx-auto d-block"
                      />
                    </div>
                  </figure>
                </div>
              </div>
              <!-- <div class="tab-pane fade active show" id="Mapa">
                <TurnosMapa
                  v-if="turno_id != null && accion == 5"
                  ref="TurnosMapa"
                />
              </div> -->
            </div>
          </div>
          <div class="container-fluid">
            <div class="card-footer">
              <div class="row">
                <gmap-map
                  :center="center"
                  :zoom="zoom"
                  style="width: 100%; height: 450px"
                  :options="{ streetViewControl: false }"
                >
                  <gmap-marker
                    :key="index"
                    v-for="(m, index) in markers"
                    :position="m.position"
                    :icon="m.icon"
                    :clickable="true"
                    :draggable="true"
                    @dragend="addMarkers"
                    @click="
                      (center = m.position),
                        toggleInfoWindow(m.position, m.id, m.info)
                    "
                  ></gmap-marker>
                  <gmap-info-window
                    :options="infoOptions"
                    :position="infoWindowPos"
                    :opened="infoWinOpen"
                    @closeclick="infoWinOpen = false"
                  >
                    <div v-html="infoContent"></div>
                  </gmap-info-window>
                  <gmap-polygon
                    v-for="poligono in poligonos"
                    :options="{ fillColor: poligono.color }"
                    :key="poligono.id"
                    :paths="poligono.path"
                    :editable="false"
                    :draggable="false"
                    @click="
                      toggleInfoWindowPoli(poligono, poligono.id, poligono.info)
                    "
                  />
                  <gmap-circle
                    v-for="circular in circunferencias"
                    :options="{ fillColor: circular.color }"
                    :key="circular.id"
                    :center="circular.center"
                    :radius="circular.radius"
                    :info="circular.info"
                    @click="
                      toggleInfoWindowCir(circular, circular.id, circular.info)
                    "
                  ></gmap-circle>
                </gmap-map>
              </div>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";

export default {
  name: "TifNovedades",
  components: {
    Loading,
    vSelect,
  },

  data() {
    return {
      cargando: false,
      accion: 1,
      flag: false,
      flagMpa: null,
      turno_id: null,
      tipo_tarifa: null,
      sitio: null,
      tipo: null,
      observacion: {},
      justificacion: false,
      tipo_j: null,
      link_fotografia: null,
      obv: null,
      miniLogo: null,
      form: {
        sitio_id: null,
        tipo_id: null,
        fecha_inicio: null,
        hora_ini: null,
        fecha_fin: null,
        hora_fin: null,
        tiempo: null,
        longitud: null,
        latitud: null,
      },
      listasForm: {
        sitio: [],
        tipo: [],
      },
      map: null,
      destino: {},
      coordinates: null,
      center: {},
      zoom: 10,
      markers: [],
      locations: {},
      poligonos: [],
      circunferencias: [],
      infoContent: "",
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
      turno: {},
      turnos: {},
      //det_puntos: {},
      det_mapa: [],
    };
  },
  validations() {
    return {
      sitio: {
        required,
      },
      tipo: {
        required,
      },
      form: {
        fecha_inicio: {
          required,
        },
        hora_ini: {
          required,
        },
        fecha_fin: {
          required,
        },
        hora_fin: {
          required,
        },
        tiempo: {
          required,
        },
        longitud: {
          required,
        },
        latitud: {
          required,
        },
      },
    };
  },

  methods: {
    toggleInfoWindow: function(position, idx, ventana) {
      this.infoWindowPos = position;
      this.infoContent = ventana;

      //compruebe si es el mismo marcador que se seleccionó en caso afirmativo alternar
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //si es un marcador diferente establece la ventana de información para abrir y restablecer el índice de marcador actual
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },

    toggleInfoWindowPoli: function(poli, idx, ventana) {
      this.infoWindowPos = poli.path[0];
      this.infoContent = ventana;
      this.center = poli.path[0];

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },

    toggleInfoWindowCir: function(cir, idx, ventana) {
      this.infoWindowPos = cir.center;
      this.infoContent = ventana;
      this.center = cir.center;

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },

    async llenar_modal_novedades(turno) {
      this.turno_id = turno.id;
      this.turnos = turno;
      this.tipo_tarifa = turno.tipo_tarifa;
      this.justificacion = false;
      this.center = {
        lat: parseFloat(this.turnos.bloque.geocerca_zona[0].latitud),
        lng: parseFloat(this.turnos.bloque.geocerca_zona[0].longitud),
      };
      await this.showGeocercas(turno);
      await this.getDatosTurno(); //await this.limpiarJust();
    },

    validaFechas() {
      this.diferenciaTiempo();
      if (this.form.fecha_inicio && this.form.fecha_fin) {
        const fecha_menor = new Date(this.form.fecha_inicio);
        const fecha_mayor = new Date(this.form.fecha_fin);
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.form.fecha_fin = null;

          this.$swal({
            icon: "error",
            title: `La fecha inicio no puede ser mayor a la fecha fin...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
        }
      }
    },

    justi(justificacion) {
      this.justificacion = true;
      this.miniLogo = this.uri_docs + justificacion.link_fotografia;
      this.tipo_j = justificacion.tipoJ;
      this.obv = justificacion.observacion;
      this.$swal({
        icon: "success",
        title:
          "Por favor rectifique la justificacion en la parte de abajo de la tabla...",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      });
    },

    async getDatosTurno() {
      this.cargando = true;
      await axios
        .get("/api/tif/conciliaciones/novedades", {
          params: {
            turno_id: this.turno_id,
          },
        })
        .then((response) => {
          this.turno = response.data;
          this.cargando = false;
        });
    },

    editar(item) {
      let duracion =
        parseFloat(item.tiempo_indisponible) +
        parseFloat(item.tiempo_disponible);

      let form = {
        id: item.id,
        observacion_front: item.observacion_front,
        duracion: duracion,
        tiempo_indisponible: item.tiempo_indisponible,
        tiempo_disponible: item.tiempo_disponible,
      };
      axios({
        method: "PUT",
        url: "/api/tif/conciliaciones/novedades/save",
        data: form,
      })
        .then((response) => {
          this.getDatosTurno();
          this.limpiarForm();
          this.center;
          this.limpiarMapa();
          this.flag = false;
          this.cargando = false;

          this.$swal({
            icon: "success",
            title: "Los datos de la novedad se guardaron exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error, vuelva a intentarlo..." + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
        });
    },

    valDuracion(item) {
      const duracion = parseFloat(item.duracion);
      const sumaDuracion =
        parseFloat(item.tiempo_indisponible) +
        parseFloat(item.tiempo_disponible);
      // Se valida que el tiempo Disponible sea menor que el tiempo Indisponible
      if (sumaDuracion > duracion) {
        item.tiempo_disponible = null;
        this.$swal({
          icon: "error",
          title: `La disponibilidad e indisponiblididad no puede ser mayor a la duración...`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        });
      } else {
        this.editar(item);
      }
    },

    getSitio() {
      this.cargando = true;
      axios
        .get("/api/tif/conciliaciones/sitio", {
          params: {
            tif_ruta_id: this.turnos.tif_ruta_id,
          },
        })
        .then((response) => {
          this.listasForm.sitio = response.data;
          this.cargando = false;
        });
    },

    obtenerImagen(e) {
      this.miniLogo = null;
      this.archivoValido = false;
      if (e.target.files[0]) {
        this.cargando = true;
        const file = e.target.files[0];
        if (this.validaArchivo(file)) {
          this.archivoValido = true;
          this.fileFotografia = file;
          this.cargarImagen(file);
        }
      }
    },

    cargarImagen(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.miniLogo = e.target.result;
      };
      reader.readAsDataURL(file);
      this.cargando = false;
    },

    validaArchivo(file) {
      let flag = true;
      if (file.size > 2048000) {
        flag = false;
        this.fileFotografia = null;
        this.cargando = false;
        this.$swal({
          icon: "error",
          title: `El Archivo es muy pesado para subirlo`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        });
      }
      return flag;
    },

    agregar() {
      this.flag = true;
      this.getSitio();
      this.getTipo();
    },

    getTipo() {
      this.cargando = true;
      axios
        .get("/api/tif/conciliaciones/tipo", {
          params: {
            tipo_tarifa: this.tipo_tarifa,
          },
        })
        .then((response) => {
          this.listasForm.tipo = response.data;
          this.cargando = false;
        });
    },

    seleccionarSitio() {
      if (this.sitio != null) {
        this.form.sitio_id = this.sitio.id;
        const destino = {
          lat: parseFloat(this.sitio.geocercas_zona.latitud),
          lng: parseFloat(this.sitio.geocercas_zona.longitud),
        };

        this.form.latitud = parseFloat(this.sitio.geocercas_zona.latitud);
        this.form.longitud = parseFloat(this.sitio.geocercas_zona.longitud);

        this.markers.push({
          position: destino,
          icon: "/img/icon_punto_g_ok.png",
          center: {
            destino,
          },
        });
        this.center = destino;
        this.zoom = 15;
        this.$swal({
          icon: "success",
          title: "Por favor elija la ubicacion de la novedad en el mapa",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        });
      } else {
        this.form.sitio_id = null;
        this.center;
      }
    },

    seleccionarTipo() {
      if (this.tipo != null) {
        this.form.tipo_id = this.tipo.numeracion;
      } else {
        this.form.tipo_id = null;
      }
    },

    save() {
      this.cargando = true;
      let form = {};
      if (this.form.tipo_id == 4) {
        form = {
          tif_turno_id: this.turno_id,
          tif_sitio_id: this.form.sitio_id,
          tipo_novedad: this.form.tipo_id,
          fecha_ini: this.form.fecha_inicio + " " + this.form.hora_ini,
          fecha_fin: this.form.fecha_fin + " " + this.form.hora_fin,
          duracion: this.form.tiempo,
          tiempo_disponible: this.form.tiempo,
          lon_ini: this.form.longitud,
          lat_ini: this.form.latitud,
        };
      } else {
        form = {
          tif_turno_id: this.turno_id,
          tif_sitio_id: this.form.sitio_id,
          tipo_novedad: this.form.tipo_id,
          fecha_ini: this.form.fecha_inicio + " " + this.form.hora_ini,
          fecha_fin: this.form.fecha_fin + " " + this.form.hora_fin,
          duracion: this.form.tiempo,
          tiempo_indisponible: this.form.tiempo,
          lon_ini: this.form.longitud,
          lat_ini: this.form.latitud,
        };
      }
      axios({
        method: "PUT",
        url: "/api/tif/conciliaciones/novedades/save",
        data: form,
      })
        .then((response) => {
          this.getDatosTurno();
          this.$parent.getIndex();
          this.limpiarForm();
          this.center;
          this.limpiarMapa();
          this.flag = false;
          this.cargando = false;

          this.$swal({
            icon: "success",
            title: "Los datos de la novedad se guardaron exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error, vuelva a intentarlo..." + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
        });
    },

    llenarMapa(item = null) {
      this.limpiarMapa();
      if (item) {
        this.flag = false;
        this.limpiarForm();
        const destino = {
          lat: parseFloat(item.lat_ini),
          lng: parseFloat(item.lon_ini),
        };
        this.markers.push({
          position: destino,
          icon: "/img/icon_punto_g_ok.png",
          center: {
            destino,
          },
        });
        this.center = destino;
        this.zoom = 10;
        this.$swal({
          icon: "success",
          title: "En el mapa se muestra la ubicacion de la novedad",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        });
      }
    },

    cancelarCambio() {
      this.cargando = true;
      this.getDatosTurno();
      this.$parent.getIndex();
      this.limpiarForm();
      this.center;
      this.limpiarMapa();
      this.flag = false;
      this.cargando = false;
    },

    cerrarModal() {
      this.cargando = true;
      this.$parent.getIndex(this.$parent.page);
      this.limpiarForm();
      this.limpiarMapa();
      //this.getDatosTurno();
      this.center;
      this.flag = false;
      this.cargando = false;
    },

    limpiarForm() {
      this.sitio = null;
      this.tipo = null;
      this.form = {
        sitio_id: null,
        tipo_id: null,
        fecha_inicio: null,
        fecha_fin: null,
        tiempo: null,
        longitud: null,
        latitud: null,
      };
    },

    limpiarMapa() {
      this.markers = [];
    },

    async addMarkers(event) {
      //this.showGeocercas()
      if (this.flag) {
        await this.limpiarMapa();
        // Datos del origen
        this.form.latitud = parseFloat(event.latLng.lat());
        this.form.longitud = parseFloat(event.latLng.lng());
        const destino = {
          lat: parseFloat(event.latLng.lat()),
          lng: parseFloat(event.latLng.lng()),
        };
        await this.markers.push({
          position: destino,
          icon: "/img/icon_punto_g_ok.png",
        });
      }
    },

    llenarModalDetTurno(turno) {
      this.$parent.$refs.TifTurnoDet.llenar_modal_detTurno(turno);
    },

    diferenciaTiempo() {
      //Diferencia tiempo fecha inicial e fecha final
      let fecha_menor = new Date(
        this.form.fecha_inicio + " " + this.form.hora_ini
      ).getTime();
      let fecha_mayor = new Date(
        this.form.fecha_fin + " " + this.form.hora_fin
      ).getTime();
      var diff = fecha_mayor - fecha_menor;
      var result = diff / (1000 * 60);
      this.form.tiempo = result.toFixed();
    },

    async showGeocercas(turno) {
      this.cargando = true;
      await axios
        .get("/api/tif/mapas/showGeocercas", {
          params: { bloque_id: turno.bloque_id },
        })
        .then((response) => {
          this.cargando = false;
          this.locations = response.data.gps_fin;
          this.locations.geocerca_poli.forEach((geocerca) => {
            if (geocerca.puntos.length > 0) {
              let puntos = [];
              geocerca.puntos.forEach((punto) => {
                puntos.unshift({
                  lng: parseFloat(punto.longitud),
                  lat: parseFloat(punto.latitud),
                });
              });
              this.poligonos.unshift({
                id: geocerca.id,
                name: geocerca.desc,
                path: puntos,
                info: geocerca.ventana,
                color: geocerca.color,
              });
            }
          });
          for (var j = 0; j < this.locations.geocerca_cir.length; j++) {
            if (this.locations.geocerca_cir[j]["lat"] !== "") {
              this.circunferencias.unshift({
                center: {
                  lat: parseFloat(this.locations.geocerca_cir[j]["lat"]),
                  lng: parseFloat(this.locations.geocerca_cir[j]["lon"]),
                },
                radius: parseFloat(this.locations.geocerca_cir[j]["radio"]),
                info: this.locations.geocerca_cir[j]["ventana"],
                color: this.locations.geocerca_cir[j]["color"],
              });
            }
          }
        })
        .catch(function(error) {
          //this.cargando = false;
        });
    },
    /* getDatosPuntos() {
      this.accion = 2;
      this.det_puntos = {};
      axios
        .get(
          "/api/hidrocarburos/ventanasEnturnamientoOperacion/ver_detalle_viaje",
          {
            params: {
              turno_id: this.turno_id,
              accion: this.accion,
            },
          }
        )
        .then((response) => {
          this.det_puntos = response.data;
        });
    }, */

    // limpiarModal() {
    //   this.turno = {};
    //   this.turno_id = null;
    // },
    // getDatosMapa() {
    //   this.accion = 5;
    //   this.det_mapa = [];
    //   axios
    //     .get("/api/tif/turnos/ver_detalle_turno", {
    //       params: {
    //         turno_id: this.turno,
    //         accion: this.accion,
    //       },
    //     })
    //     .then((response) => {
    //       this.det_mapa = response.data;
    //     });
    // },
  },

  mounted() {},
};
</script>
